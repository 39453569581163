import React from "react";
import Modal from '@material-ui/core/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import TimeZones from '../assets/json/time_zones.json';

const TimezoneItem = ({timezone, onClose, tz}) => {
    return (
        <div className={`timezone_item ${timezone == (tz.gmtOffSet + ' ' + tz.timeZoneLabel) ? 'selected': ''}`} onClick={() => onClose(tz.gmtOffSet + ' ' + tz.timeZoneLabel, tz.timeZoneId)}>
            {tz.gmtOffSet + ' ' + tz.timeZoneLabel}
        </div>
    )
}

const TimezoneModal = ({timezone = "", timeZoneId = "", open, onClose, ...props}) => {
    return (
        <Modal 
            open={open || false}
            onClose={onClose || function () {}}
            {...props}
        >
            <div className="c_modal tz_modal">
                <div className="sub_cmodal">
                    <div className="modal_header">
                        <div className="title">
                            Time Zone
                        </div>
                        <div className="btn_close" onClick={() => onClose(timezone, timeZoneId)}>
                            <FontAwesomeIcon className="fa_icon" icon={faTimes} />
                        </div>
                    </div>
                    <div className="modal_content">
                        {
                            TimeZones.map((tz) => <TimezoneItem key={tz.id} timezone={timezone} onClose={onClose} tz={tz} />)
                        }
                    </div>
                </div>
            </div>
        </Modal>
    ) 
}

export default TimezoneModal;