import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faLock, faCalendar, faClock, faGlobeAsia, faVideo, faCamera } from '@fortawesome/free-solid-svg-icons'
import { navigate } from "gatsby";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker
  } from '@material-ui/pickers';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core';

import Layout from "../components/layout";
import TimezoneModal from "../components/timezone-modal";
import InviteModal from "../components/invite-modal";

import { generateMeetingId } from '../helpers/firebase';
import { getRndNumericString } from '../helpers/util';

const ScheduleMeeting = () => {
    const [loading, setLoading] = useState(false);
    const [roomName, setRoomName] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [date, setDate] = useState(new Date());
    const [fromTime, setFromTime] = useState(new Date());
    const [toTime, setToTime] = useState(new Date());
    const [timezone, setTimezone] = useState('(GMT+5:30) India');
    const [timeZoneId, setTimeZoneId] = useState('Asia/Calcutta');
    const [hostVideoOn, setHostVideoOn] = useState(true);
    const [participantVideoOn, setParticipantVideoOn] = useState(true);
    const [microphoneOn, setMicrophoneOn] = useState(true);
    const [ivModalData, setIvModalData] = useState(null);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [isFromTimePickerOpen, setIsFromTimePickerOpen] = useState(false);
    const [isToTimePickerOpen, setIsToTimePickerOpen] = useState(false);
    const [isTimeZoneModalOpen, setIsTimeZoneModalOpen] = useState(false);
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);

    const handlePasswordShow = (event) => {
        if (!passwordShow && !password) {
            const password = getRndNumericString(4);
            setPassword(password);
        }
        setPasswordShow(!passwordShow);
    }

    const handleSave = async () => {
        let startTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), fromTime.getHours(), fromTime.getMinutes());
        let endTime = new Date(date.getFullYear(), date.getMonth(), date.getDate(), toTime.getHours(), toTime.getMinutes());

        startTime = Math.floor(startTime.getTime() / 1000);
        endTime = Math.floor(endTime.getTime() / 1000);

        if (startTime > endTime) {
            alert('Please enter valid start time, end time');
            return;
        }
        setLoading(true);
        const data = await generateMeetingId('scheduled', displayName, password, hostVideoOn, microphoneOn, participantVideoOn, microphoneOn, startTime, endTime, timeZoneId);
        setIvModalData(data);
        setLoading(false);
        setIsInviteModalOpen(true);
    }
   
    return (
        <Layout>
            <TimezoneModal 
                timezone={timezone}
                open={isTimeZoneModalOpen}
                onClose={(timezone, timeZoneId) => {
                    setTimezone(timezone);
                    setTimeZoneId(timeZoneId);
                    setIsTimeZoneModalOpen(false);
                }}
            />
            <InviteModal 
                open={isInviteModalOpen}
                inviteMessageTitle = {ivModalData ? ivModalData.meetingDetails.inviteMessage.title : ''}
                inviteMessageBody = {ivModalData ? ivModalData.meetingDetails.inviteMessage.body : ''}
                inviteMessageFooter = {ivModalData ? ivModalData.meetingDetails.inviteMessage.footer : ''}
                meetingTitle = {ivModalData ? ivModalData.meetingDetails.meetingTitle : ''} 
                meetingId = {ivModalData ? ivModalData.meetingId : ''}
                meetingPassword = {ivModalData ? ivModalData.meetingDetails.meetingPassword : ''}
                meetingTime = {ivModalData ? ivModalData.meetingDetails.startTime : ''}
                onClose={(type) => {
                    setIsInviteModalOpen(false);
                }}
            />
            <div className="center_colm">
                <div className="center_colm_title">
                    <div className="btn_back" onClick={() => navigate("/dashboard")}>
                        <FontAwesomeIcon className="fa_icon" icon={faChevronLeft} />
                    </div>
                    Schedule a meeting
                </div>
                <div className="ct_item">
                    <label>Enter meeting name*</label>
                    <input type="text" name="" placeholder="Enter a meeting name here" autoFocus value={displayName} onChange={(event) => setDisplayName(event.target.value)}/>
                </div>
                <div className="options">
                    <span>Options</span>
                    <ul className="list-unstyled">
                        <li className="m_psw">
                            {
                                passwordShow ? 
                                    <input type="text" name="meeting_password" id="meeting_password" className="meeting_password" placeholder="Enter a meeting password" value={password} onChange={(event) => setPassword(event.target.value)} />
                                :
                                    <div>
                                        <FontAwesomeIcon className="fa_icon" icon={faLock} />
                                        <a href="#">Meeting password*</a>
                                    </div>
                            }
                            <label className="switch chk_password" htmlFor="password">
                                <input type="checkbox" id="password" value={passwordShow} onChange={(event) => handlePasswordShow(event)} />
                                <span className="slider round"></span>
                            </label>
                        </li>
                        <li className="m_psw click_item" onClick={() => setIsDatePickerOpen(true)}>
                            <div>
                                <FontAwesomeIcon className="fa_icon" icon={faCalendar} />
                                <a href="#">Date</a>
                            </div>
                            <label className="switch option_date">{Moment(date).format('D MMM YYYY')}</label>
                        </li>
                        <li className="m_psw click_item" onClick={() => setIsFromTimePickerOpen(true)}>
                            <div>
                                <FontAwesomeIcon className="fa_icon" icon={faClock} />
                                <a href="#">From (Time)</a>
                            </div>
                            <label className="switch option_date">{Moment(fromTime).format('h:mm a')}</label>
                        </li>
                        <li className="m_psw click_item" onClick={() => setIsToTimePickerOpen(true)}>
                            <div>
                                <FontAwesomeIcon className="fa_icon" icon={faClock} />
                                <a href="#">To (Time)</a>
                            </div>
                            <label className="switch option_date">{Moment(toTime).format('h:mm a')}</label>
                        </li>
                        <li className="m_psw click_item" onClick={() => setIsTimeZoneModalOpen(true)}>
                            <div>
                                <FontAwesomeIcon className="fa_icon" icon={faGlobeAsia} />
                                <a href="#">Time Zone</a>
                            </div>
                            <label className="switch option_date">{timezone}</label>
                        </li>
                        <li className="m_psw">
                            <div>
                                <FontAwesomeIcon className="fa_icon" icon={faVideo} />
                                <a href="#">Host video on</a>
                            </div>
                            <label className="switch">
                                <input type="checkbox" defaultChecked value={hostVideoOn} onChange={(event) => setHostVideoOn(event.target.value)} />
                                <span className="slider round"></span>
                            </label>
                        </li>
                        <li className="m_psw">
                            <div>
                                <FontAwesomeIcon className="fa_icon" icon={faCamera} />
                                <a href="#">Participant video on</a>
                            </div>
                            <label className="switch">
                                <input type="checkbox" defaultChecked value={participantVideoOn} onChange={(event) => setParticipantVideoOn(event.target.value)} />
                                <span className="slider round"></span>
                            </label>
                        </li>
                    </ul>
                    <div className="invite_save_btn">
                        <button className="start_m_btn btn" onClick={() => handleSave()} disabled={!displayName || !password}>
                            {
                                loading ? 
                                    <CircularProgress />
                                :
                                    "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    open={isDatePickerOpen}
                    onOpen={() => setIsDatePickerOpen(true)}
                    onClose={() => setIsDatePickerOpen(false)}
                    format="MM/dd/yyyy"
                    value={date}
                    onChange={(date) => setDate(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    hidden={true}
                />
                <KeyboardTimePicker
                    margin="normal"
                    id="from-time-picker"
                    open={isFromTimePickerOpen}
                    onOpen={() => setIsFromTimePickerOpen(true)}
                    onClose={() => setIsFromTimePickerOpen(false)}
                    value={fromTime}
                    onChange={(time) => setFromTime(time)}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    hidden={true}
                />
                <KeyboardTimePicker
                    margin="normal"
                    id="to-time-picker"
                    open={isToTimePickerOpen}
                    onOpen={() => setIsToTimePickerOpen(true)}
                    onClose={() => setIsToTimePickerOpen(false)}
                    value={toTime}
                    onChange={(time) => setToTime(time)}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    hidden={true}
                />
            </MuiPickersUtilsProvider>
        </Layout>
    )
}
export default ScheduleMeeting; 


